import { format, fromUnixTime, getUnixTime } from "date-fns";
import { useAuthUser, withAuthorization } from "../../components/Session";

import CancelMembershipForm from "../../components/Account/CancelMembershipForm";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
import PasswordChangeForm from "../../components/PasswordChange";
import PaymentSource from "../../components/Account/PaymentSource";
import React from "react";
import UndoCancelForm from "../../components/Account/UndoCancelForm";
import UpgradeSubscription from "../../components/Account/UpgradeSubscription";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import PageHeader from "../../components/PageHeader";
import Seo from "../../components/seo";
import * as ROUTES from "../../constants/routes";

const defaults = {
  goal_last0: 0,
  goal_last1: 0,
  goal_last2: 0,
  goal_last3: 0,
  goal_last4: 0,
  goal_last5: 0,
  goal_count0: 0,
  goal_count1: 0,
  goal_count2: 0,
  goal_count3: 0,
  goal_count4: 0,
  goal_count5: 0,
  goal_lock0: 0,
  goal_lock1: 0,
  goal_lock2: 0,
  goal_lock3: 0,
  goal_lock4: 0,
  goal_lock5: 0,
};

const AccountPageBase = ({ firebase }) => {
  const {
    uid,
    email,
    presale,
    username,
    subscription_status,
    current_period_end,
    goal_start_date,
  } = useAuthUser();
  const [message, setMessage] = React.useState(null);
  const [confirmReset, setConfirmReset] = React.useState(false);
  const [goalStart, setGoalStart] = React.useState(goal_start_date);

  React.useEffect(() => {
    if (goalStart !== goal_start_date) {
      setGoalStart(goal_start_date);
    }
  }, [goal_start_date, goalStart]);

  const handleMessage = (msg) => setMessage(msg);

  return (
    <div className="account-page">
      <Seo
        title="Account Settings"
        description="Account settings to manage your subscription details, password and obtain getting started information"
      />
      <PageHeader title={"Account Settings"} />
      <section className="section">
        <div className="container content">
          {" "}
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div>
                <h2 className="title is-size-2 has-text-weight-bold is-bold-light">
                  Welcome, {username}!
                </h2>
                <p>
                  Here you can manage your account settings, change your
                  password or change subscription information.
                </p>
                <p>
                  <strong>Email:</strong> {email}
                </p>
                {presale ? <p>Early Signup Member</p> : null}
                <br />
                <br />
                <h4 className="title is-size-4 has-text-weight-bold is-bold-light">
                  <Link className="light-link" to={ROUTES.GETTING_STARTED}>
                    Getting Started
                  </Link>
                </h4>

                <h4 className="title is-size-4 has-text-weight-bold is-bold-light">
                  <Link className="light-link" to={ROUTES.LOGBOOK}>
                    Logbook
                  </Link>
                </h4>
                <h4 className="title is-size-4 has-text-weight-bold is-bold-light">
                  Back to BaSIX
                </h4>
                <p>
                  <strong>Start date:</strong>{" "}
                  {format(
                    fromUnixTime(goalStart ? goalStart : new Date()),
                    "MM/dd/yyyy"
                  )}
                </p>

                {confirmReset === false && (
                  <button
                    className="no-promo"
                    onClick={() => {
                      setConfirmReset(true);
                    }}
                  >
                    Reset
                  </button>
                )}
                {confirmReset === true && (
                  <>
                    <span>
                      This will reset your goal start date, bringing you back to
                      week 1 of Back to BaSIX. Continue?
                    </span>
                    <br />
                    <button
                      className="no-promo"
                      onClick={() => {
                        const now = new Date();
                        const newDate = getUnixTime(now);
                        firebase
                          .user(uid)
                          .set(
                            { ...defaults, goal_start_date: newDate },
                            { merge: true }
                          )
                          .then(() => {
                            setGoalStart(newDate);
                            setConfirmReset(false);
                          });
                      }}
                    >
                      Yes
                    </button>
                    <br />
                    <br />
                    <button
                      className="no-promo"
                      onClick={() => {
                        setConfirmReset(false);
                      }}
                    >
                      Cancel
                    </button>
                  </>
                )}
                <br />
                <br />
              </div>
              <div>
                <h4 className="title is-size-4 has-text-weight-bold is-bold-light">
                  Subscription
                </h4>
                <div>
                  <UpgradeSubscription onMessage={handleMessage} />
                  <strong>Status:</strong>
                  {` `}
                  {subscription_status
                    ? subscription_status.charAt(0).toUpperCase() +
                      subscription_status.slice(1)
                    : null}
                  {subscription_status === "canceling" && (
                    <UndoCancelForm onMessage={handleMessage} />
                  )}
                </div>
                <p>
                  <strong>Current period ends:</strong>
                  {` `}
                  {current_period_end
                    ? format(fromUnixTime(current_period_end), "MM/dd/yyyy")
                    : `Pending payment`}
                </p>
                <PaymentSource />
                <br />
                <CancelMembershipForm onMessage={handleMessage} />

                {!!message && (
                  <ul>
                    <li className="form-row">
                      <label
                        style={{
                          backgroundColor: "#FF9F1C",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {message}
                      </label>
                    </li>
                  </ul>
                )}
                <br />
              </div>
              <div>
                <h4 className="title is-size-4 has-text-weight-bold is-bold-light">
                  Change Password
                </h4>
                <PasswordChangeForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const condition = (authUser) => !!authUser;

const AccountPage = compose(
  withAuthorization(condition),
  withFirebase
)(AccountPageBase);

const AccountPageFinal = () => (
  <Layout>
    <AccountPage />
  </Layout>
);

export default AccountPageFinal;
