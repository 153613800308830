import React from "react";
import { compose } from "recompose";
import { useAuthUser } from "../Session";
import { withFirebase } from "../Firebase";

function UndoCancelFormBase({ firebase, onMessage }) {
  const authUser = useAuthUser();
  const { subscription_status: status } = authUser;
  const [showForm, setShowForm] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(true);
  const [posting, setPosting] = React.useState(false);

  if (!status || status !== "canceling") {
    return null;
  }

  if (!showForm) {
    return (
      <>
        {" "}
        <button
          className="no-promo"
          onClick={() => {
            setShowForm(true);
          }}
        >
          Undo Cancel
        </button>
      </>
    );
  }

  async function reactivateAccount(e) {
    e.preventDefault();
    const subId = authUser.stripe_sub_id;

    setCanSubmit(false);
    setPosting(true);
    try {
      const result = await fetch(
        "/.netlify/functions/undo-cancel-subscription",
        {
          method: "POST",
          body: JSON.stringify({
            subId,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await result.json();
      if (json.status === "success") {
        await firebase
          .user(authUser.uid)
          .set({ cancel_reason: null, cancel_other: null }, { merge: true });
        onMessage(
          "Your account activation has been requested, your account settings should reflect the change shortly."
        );
      } else {
        onMessage(
          `There was an error while reactivating your account, please contact us: ${result.message}`
        );
      }
    } catch (err) {
      onMessage(
        "There was an issue reactivating your account, please contact us for assistance."
      );
      // something went wrong
    }
    setPosting(false);
    setShowForm(false);
  }

  return (
    <div>
      <form>
        <ul className="form-wrapper">
          <li className="form-row">Stop your account from canceling?</li>
          <li className="form-row">
            <button
              className="fancy"
              onClick={() => {
                setShowForm(false);
              }}
              disabled={posting}
            >
              Cancel
            </button>
            <button
              className="fancy"
              disabled={!canSubmit || posting}
              onClick={reactivateAccount}
            >
              {posting
                ? `Reactivating account...`
                : `Please reactivate my account!`}
            </button>
          </li>
        </ul>
      </form>
    </div>
  );
}

const UndoCancelForm = compose(withFirebase)(UndoCancelFormBase);

export default UndoCancelForm;
