import React from "react";

import { useAuthUser } from "../Session";
import UpdatePayment from "../UpdatePayment";

function PaymentSourceBase() {
  const [loading, setLoading] = React.useState(true);
  const [brand, setBrand] = React.useState("CardType");
  const [last4, setLast4] = React.useState("Last4");
  const [error, setError] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [expiration, setExpiration] = React.useState("");

  const authUser = useAuthUser();

  React.useEffect(() => {
    async function getData() {
      setError(false);
      try {
        fetch("/.netlify/functions/get-customer-payment-source", {
          method: "POST",
          body: JSON.stringify({
            customerId: authUser.stripe_id,
          }),
        })
          .then((resp) => resp.json())
          .then((cardInfo) => {
            if (cardInfo.status === "success") {
              setBrand(cardInfo.brand);
              setLast4(cardInfo.last4);
              if (cardInfo.expMonth) {
                setExpiration(`${cardInfo.expMonth} / ${cardInfo.expYear}`);
              }
            }

            setLoading(false);
          });
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    }

    if (authUser) {
      getData();
    }
  }, [showEdit, authUser]);

  if (error) {
    return <div>Error retrieving card data.</div>;
  }

  if (loading) {
    return <div>Loading data...</div>;
  }

  if (showEdit) {
    return (
      <UpdatePayment
        showCancel={true}
        onCancel={() => setShowEdit(false)}
        onComplete={() => setShowEdit(false)}
        isUpdate={true}
      />
    );
  }

  return (
    <div>
      <strong>Payment Method:</strong> {brand} ending in {last4}
      {expiration.length > 0 && `, expires ${expiration}`}{" "}
      <button
        className="no-promo"
        onClick={() => {
          setShowEdit(true);
        }}
      >
        Change
      </button>
    </div>
  );
}

export default PaymentSourceBase;
