import React from "react";
import { compose } from "recompose";
import { Link } from "gatsby";
import { useAuthUser } from "../Session";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const REASONS = [
  "Select a reason...",
  "The workouts were not what I expected",
  "I have lost my motivation",
  "I joined another workout program/fitness facility",
  "It's too expensive",
  "Other",
  "COVID-19",
];

function CancelMembershipFormBase({ firebase, onMessage }) {
  const authUser = useAuthUser();
  const { subscription_status: status } = authUser;
  const [showForm, setShowForm] = React.useState(false);
  const [showOther, setShowOther] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [reason, setReason] = React.useState("none");
  const [other, setOther] = React.useState("");
  const [posting, setPosting] = React.useState(false);

  React.useEffect(() => {
    if (reason === "Other") {
      setShowOther(true);
      setCanSubmit(false);
    } else {
      setShowOther(false);
      setOther("");
      setCanSubmit(reason !== "none");
    }
  }, [reason]);

  React.useEffect(() => {
    if (other.length > 0) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [other]);

  const handleReasonChange = (e) => setReason(e.target.value);
  const handleOtherChange = (e) => setOther(e.target.value);

  if (!status || (status !== "active" && status !== "trialing")) {
    return null;
  }

  if (!showForm) {
    return (
      <div>
        <button
          className="no-promo"
          onClick={() => {
            setShowForm(true);
          }}
        >
          I'd like to cancel my membership.
        </button>
      </div>
    );
  }

  async function cancelAccount(e) {
    e.preventDefault();
    const subId = authUser.stripe_sub_id;

    setCanSubmit(false);
    setPosting(true);
    try {
      const result = await fetch("/.netlify/functions/cancel-subscription", {
        method: "POST",
        body: JSON.stringify({
          subId,
          email: authUser.email,
          username: authUser.username,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const json = await result.json();
      if (json.status === "success") {
        await firebase
          .user(authUser.uid)
          .set({ cancel_reason: reason, cancel_other: other }, { merge: true });
        onMessage(
          "Your account cancellation has been requested, your account settings should reflect the change shortly. Your account will continue to work until the billing period has ended."
        );
      } else {
        onMessage(
          `There was an error while canceling your account, please contact us: ${result.message}`
        );
      }
    } catch (err) {
      onMessage(
        "There was an issue canceling your account, please contact us for assistance."
      );
      // something went wrong
    }
    setPosting(false);
    setShowForm(false);
  }

  return (
    <div>
      <form>
        <ul className="form-wrapper">
          <li className="form-row">
            Please let us know why you'd like to cancel your account so we can
            improve our services:
            <select onBlur={handleReasonChange}>
              {REASONS.map((x, index) => (
                <option
                  key={`cancel-reason-option-${index}`}
                  value={index === 0 ? "none" : x}
                >
                  {x}
                </option>
              ))}
            </select>
            {showOther && (
              <input
                className="reason-member"
                name="otherReason"
                type="text"
                onChange={handleOtherChange}
                placeholder="Please describe your reason"
                value={other}
              />
            )}
          </li>
          {reason === "COVID-19" && (
            <div className="notification is-info">
              It is our priority is to keep our weekly workout family #balanced!
              We are offering special membership accommodations to those in
              need, so please reach out via the{" "}
              <Link to={ROUTES.CONTACT}>Contact</Link> page so we can continue
              doing everything we can to keep you mentally, physically and
              emotionally healthy! ❤️️ Beef & Cheese
            </div>
          )}
          <li className="form-row">
            <button
              className="fancy"
              onClick={() => {
                setShowForm(false);
              }}
            >
              Actually, I'm staying!
            </button>
            <button
              className="fancy"
              disabled={!canSubmit || posting}
              onClick={cancelAccount}
            >
              {posting ? `Canceling account...` : `Please cancel my account`}
            </button>
          </li>
        </ul>
      </form>
    </div>
  );
}

const CancelMembershipForm = compose(withFirebase)(CancelMembershipFormBase);

export default CancelMembershipForm;
