import React from "react";
import { compose } from "recompose";
import { v4 } from "uuid";
import { Elements, injectStripe, CardElement } from "react-stripe-elements";
import { withFirebase } from "../../components/Firebase";
import { StripeWrapper } from "../Stripe/StripeWrapper";
import stripeBadge from "../../img/powered_by_stripe.png";
import { useAuthUser } from "../Session";

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Poppins",
        "::placeholder": {
          color: "#aab7c4",
        },
        padding,
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

function UpdatePaymentFormBase({
  stripe,
  firebase,
  showCancel,
  onCancel,
  onComplete,
  isUpdate,
}) {
  const [payment, setPayment] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const authUser = useAuthUser();

  const onPaymentChange = (event) => setPayment(event);

  async function onSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    // user does not yet have a stripe id for some reason
    if (!authUser.stripe_id) {
      // set up the customer record
      stripe.createToken().then((payload) => {
        const customer = JSON.stringify({
          token: payload.token,
          name: authUser.username,
          email: authUser.email,
          phone: "",
          uid: authUser.uid,
          freeWeek: false,
          idempotency_key: v4(),
        });
        fetch("/.netlify/functions/create-customer", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: customer,
        })
          .then((res) => res.json())
          .then(async (data) => {
            if (data.status === "succeeded") {
              const { presale, stripe_id, expiration, created_on } = data;
              await firebase.user(authUser.uid).set(
                {
                  presale,
                  stripe_id,
                  expiration,
                  created_on,
                  subscription_status: "ready",
                },
                { merge: true }
              );

              // // now make the charge
              // await doStripeCharge().then(async subscription =>{
              //   doFirestoreUpdate(subscription)
              // })
              setSuccess(true);
              onComplete();
            } else {
              setError(`Error setting up customer record: ${data.message}`);
              setIsLoading(false);
            }
          });
      });
    } else {
      // have a stripe id
      // if it's just an update for an active account
      if (isUpdate) {
        stripe.createToken().then((payload) => {
          const update = JSON.stringify({
            token: payload.token,
            stripe_id: authUser.stripe_id,
            idempotency_key: v4(),
          });

          fetch("/.netlify/functions/update-customer-payment-source", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: update,
          })
            .then((res) => res.json())
            .then(async (data) => {
              if (data.status === "succeeded") {
                // worked new payment method was assigned
                onComplete();
              } else {
                // error handling
              }
            });
        });

        // otherwise, brought here because payment failed
        //
      }
    }
  }

  const paymentInvalid = !payment || payment.complete === false;
  const isInvalid = paymentInvalid;

  if (success) {
    return <div>Successfully updated, thank you.</div>;
  }

  return (
    <form onSubmit={onSubmit}>
      <ul className="form-wrapper">
        <li className="form-row">
          <label>
            <strong>Payment Details * </strong>
            <p>
              <a href="https://stripe.com" tabIndex="-1">
                <img
                  src={stripeBadge}
                  alt="Secure checkout powered by Stripe"
                />
              </a>
            </p>
            {payment && payment.error && (
              <p className="field-error">{payment.error.message}</p>
            )}
          </label>
          <div className="checkout">
            <CardElement
              name="payment"
              onChange={onPaymentChange}
              {...createOptions()}
            />
          </div>
        </li>
        <li className="form-row">
          <button
            className="fancy"
            disabled={isInvalid || isLoading || error}
            type="submit"
          >
            {isLoading
              ? "Updating..."
              : error
              ? `An error occurred, see below.`
              : `Update Payment`}
          </button>
        </li>
        {showCancel && (
          <li className="form-row">
            <button className="fancy" disabled={isLoading} onClick={onCancel}>
              Cancel
            </button>
          </li>
        )}
        <li className="form-row">
          <p className="meta">* Denotes required field</p>
        </li>
        {error && (
          <li className="form-row">
            <p className="field-error overall-error">{error}</p>
          </li>
        )}
      </ul>
    </form>
  );
}

const UpdatePaymentForm = compose(
  withFirebase,
  injectStripe
)(UpdatePaymentFormBase);

const UpdatePaymentFormFinal = (props) => (
  <StripeWrapper>
    <Elements
      fonts={[
        {
          cssSrc:
            "https://fonts.googleapis.com/css?family=Poppins:300,300i,400,500,600)",
        },
      ]}
    >
      <UpdatePaymentForm {...props} />
    </Elements>
  </StripeWrapper>
);

export default UpdatePaymentFormFinal;
