import React from "react";
import { PRICING } from "../../constants/pricing";
import { useAuthUser } from "../Session";

const SUB_TYPES = {
  0: "1 Month (billed $14.99 every month)",
  1: "3 Month (billed $39.99 every 3 months)",
  2: "6 Month (billed $76.99 every 6 months)",
  3: "Black Friday 12 Month (billed $120.00 every 12 months)",
  4: "Black Friday 6 Month (billed $75.00 every 6 months)",
  5: "1 Month (billed $19.99 every month)",
  6: "3 Month (billed $54.99 every 3 months)",
  7: "6 Month (billed $99.99 every 6 months)",
  8: `1 Month (billed $${PRICING.MONTHLY} every month)`,
  9: `3 Month (billed $${PRICING.TRI_MONTHLY} every 3 months)`,
  10: `6 Month (billed $${PRICING.SEMI_ANNUALLY} every 6 months)`,
};

export default function UpgradeSubscription({ onMessage }) {
  const { subscriptionType, stripe_sub_id: subId, uid } = useAuthUser();
  const [showUpgrade, setShowUpgrade] = React.useState(false);
  const [type, setType] = React.useState(0);
  const [posting, setPosting] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const subType = subscriptionType || 0;
  const formatSubscriptionType = SUB_TYPES[subType];

  function submitForm() {
    setPosting(true);
    fetch("/.netlify/functions/upgrade-subscription", {
      method: "POST",
      body: JSON.stringify({
        uid,
        subId,
        newType: type,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        let message = null;
        if (result.status === "success") {
          message =
            "Your account upgrade has been requested, your account settings should reflect the change shortly.";
        } else {
          message = `There was an error while upgrading your account, please contact us.`;
        }

        setShowUpgrade(false);
        setConfirm(false);
        onMessage(message);
      })
      .catch(() => {
        setShowUpgrade(false);
        setConfirm(false);
        onMessage(
          `There was an error while upgrading your account, please contact us.`
        );
      })
      .finally(() => {
        setPosting(false);
      });
  }

  // cannot downgrade
  if (subType === 2) {
    return (
      <div>
        <strong>Type: </strong>
        {formatSubscriptionType}
      </div>
    );
  }

  if (!showUpgrade) {
    return (
      <div>
        <strong>Type: </strong>
        {formatSubscriptionType}{" "}
        <button
          className="no-promo"
          onClick={() => {
            setShowUpgrade(true);
          }}
        >
          Upgrade
        </button>
      </div>
    );
  }

  if (confirm) {
    return (
      <div>
        <ul className="form-wrapper">
          <li className="form-row">
            <div>
              <strong>Type</strong>
            </div>
            <div>
              Confirm upgrade to <strong>{SUB_TYPES[type]}</strong>
            </div>
            <button
              className="fancy"
              disabled={posting}
              onClick={() => {
                submitForm();
              }}
            >
              Yes, purchase!
            </button>
            <button
              className="fancy"
              disabled={posting}
              onClick={() => {
                setType(5);
                setConfirm(false);
              }}
            >
              Cancel
            </button>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div>
      <ul className="form-wrapper">
        <li className="form-row">
          <div>
            <strong>Type</strong>
          </div>
          <div>
            Select a subscription type above your current (you will be asked to
            confirm this choice)
          </div>
          <div>
            <strong>Pricing:</strong>
            {subType < 10 && <div> - {SUB_TYPES[10]}</div>}
            {subType < 9 && <div> - {SUB_TYPES[9]}</div>}
          </div>
          {subType < 10 && (
            <button
              className="fancy"
              onClick={() => {
                setType(10);
                setConfirm(true);
              }}
            >
              6 Month
            </button>
          )}
          {subType < 9 && (
            <button
              className="fancy"
              onClick={() => {
                setType(9);
                setConfirm(true);
              }}
            >
              3 Month
            </button>
          )}
          <button
            className="fancy"
            disabled={posting}
            onClick={() => {
              setShowUpgrade(false);
              setConfirm(false);
            }}
          >
            No changes
          </button>
        </li>
      </ul>
    </div>
  );
}
